<template>
  <v-container fluid class="min-height">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="animate__animated animate__fadeInLeft animate__faster">
          <v-card-title class="primary white--text">
            <v-icon class="mr-2 white--text">mdi-settings</v-icon>
            Configuración
          </v-card-title>
          <v-card-text class="py-4">
            <span v-for="item in items" :key="item.title">
              <v-btn
                class="resultContainer my-2"
                block
                x-large
                rounded
                text
                v-if="!item.click"
              >
                <v-icon class="mr-4">{{ item.icon }}</v-icon>
                <span>{{ item.title }}</span>
                <v-spacer />
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
              <v-btn
                class="resultContainer my-2"
                block
                x-large
                rounded
                text
                v-if="item.click"
                @click="cerrarSesion"
              >
                <v-icon class="mr-4">{{ item.icon }}</v-icon>
                <span>{{ item.title }}</span>
                <v-spacer />
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Configuracion",
  data: () => ({
    items: [
      { title: "Privacidad", icon: "mdi-account-settings" },
      { title: "Temas", icon: "mdi-format-paint" },
      { title: "Ayuda y soporte", icon: "mdi-help" },
      { title: "Acerca de Smart-Pill", icon: "mdi-information" },
      { title: "Cerrar sesión", icon: "mdi-logout", click: true },
    ],
  }),
  methods: {
    cerrarSesion() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<!--<style scoped>
.task-list-margen {
Text-align: start
}
</style>-->

<style scoped>
.resultContainer {
  height: 350px;
}

.item {
  min-height: 50px;
  min-width: 80px;
  margin: 10px;
}
</style>
